* {
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-flow: row;
}
.header-row {
    width: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.header-row:nth-of-type(1) {
    margin-right: 2.5%;
}
.header-row:nth-of-type(2) {
    margin-left: 2.5%;
}

.header .header-row:first-of-type {
    align-self: center;
    flex-flow: column;
}
.header .header-row:nth-of-type(2) {
    justify-content: center;
}

.header-container, .skills-container, .projects-container, .worktimeline-container {
    margin-top:10%;
    margin-bottom:10%;
}

.header-row div:first-of-type{
    font-size: 1.5em;
}

.header-row h2 {
    font-size: inherit;
}

.header img {
    border: 1px transparent solid;
    border-radius: 50% 50%;
    width: 100%;
    max-width: 400px;
    height: auto
}

h3,h4 {
    margin:5%;
    text-align: center;
}
.skills {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
}

.skills > div {
    background-color: rgba(15, 15, 15,0.8);
    flex-basis: 40%;
    border: 1px solid black;
    border-radius: 5%;
    box-shadow: 0 0 5px #0B0D19;
}

.skills > div > h4 {
    text-align: center;
    color: rgb(0, 102, 204);
}

.skills-frontend, .skills-backend {
    display: flex;
    flex-flow: row wrap;
}
.skill-item {
    flex-basis: 33%;
    padding: 2rem;
}

.icons {
    display: flex;
    gap: 2%;
    justify-content: center;
    align-content: center;
}

.contact {
    display: flex;
    justify-content: center;
}
.contact form {
    display:flex;
    flex-direction: column;
    flex-basis: 50%;
    gap: 10%;
}

.contact form button {
    width: 90px;
}

.projects {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;
}
.project-card {
    flex-basis: 420px;
    border: 1px solid floralwhite;
    background-color: floralwhite;
    color: black;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
}
.project-card-description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.project-card-description small {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.project-card-links {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.project-card-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.project-card-link span {
    font-size: 12px;
}
.project-card-tags {
    display: flex;
    flex-direction: column;
}
.project-card-tags small {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.project-card-tag-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.project-card-tag {
    background-color: lightgrey;
    border: 2px solid transparent;
    border-radius: 1rem;
    padding: 5px;
    font-size: 10px;
}
.divider {
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
}
.worktimeline div {
    margin: 0;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .header {
        display: flex;
        flex-flow: column-reverse;
    }
    .header-row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .header-row:nth-of-type(1) {
        margin-right: 0;
    }
    .header-row:nth-of-type(2) {
        margin-left: 0;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .header {
        display: flex;
        flex-flow: column-reverse;
    }
    .header-row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;

    }
    .header-row:nth-of-type(1) {
        margin-right: 0;
    }
    .header-row:nth-of-type(2) {
        margin-left: 0;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .header {
        display: flex;
        flex-flow: row;
        text-align: right;
    }
    .header-row {
        width: 50%;
        display: flex;
        justify-content: center;
        align-content: center;

    }
    .header-row:nth-of-type(1) {
        margin-right: 2.5%;
    }
    .header-row:nth-of-type(2) {
        margin-left: 2.5%;
    }
    .icons {
        display: flex;
        gap: 2%;
        justify-content: flex-end;
        align-content: flex-end;
    }
    .header .header-row:nth-of-type(2) {
        justify-content: flex-start;
    }
    .skills {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 5%;
        justify-content: center;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
